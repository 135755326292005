<template>
  <ion-grid>
    <ion-row class="ionic-padding">
      <ion-col>
        <ion-card
          button
          title="Registrar Ingreso"
          router-link="/entrada/Ingreso"
        >
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="enterOutline"
            ></ion-icon>
            <ion-card-subtitle>Registrar Ingreso</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card button title="Registrar Salida" router-link="/entrada/Salida">
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="exitOutline"
            ></ion-icon>
            <ion-card-subtitle>Registrar Salida</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col v-if="user.role ==='admin' || user.role ==='gestor'">
        <ion-card button title="Listar items" router-link="/items/Items">
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="listOutline"
            ></ion-icon>
            <ion-card-subtitle>Listar Items</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card
          button
          title="Registro Item"
          router-link="/additem/Nuevo"
        >
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="addCircleOutline"
            ></ion-icon>
            <ion-card-subtitle>Registro Item</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-card
          button
          title="Reportar Novedad"
          router-link="/novedad/Novedad"
        >
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="warningOutline"
            ></ion-icon>
            <ion-card-subtitle>Reportar Novedad</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col>
        <ion-card
          button
          title="Cambiar Contraseña"
          router-link="/password/Cambiar Contraseña"
        >
          <ion-card-content>
            <ion-icon
              color="primary"
              size="large"
              slot="icon-only"
              :icon="shieldOutline"
            ></ion-icon>
            <ion-card-subtitle>Cambio Contraseña</ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
} from "@ionic/vue";
import {
  shieldOutline,
  enterOutline,
  exitOutline,
  warningOutline,
  listOutline,
  addCircleOutline
} from "ionicons/icons";
import { mapGetters } from 'vuex';
export default {
  name: "home-component",
  components: {
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
  },
  data() {
    return {
      shieldOutline,
      enterOutline,
      exitOutline,
      warningOutline,
      listOutline,
      addCircleOutline
    };
  },
  computed:{
      ...mapGetters('user',['user'])
  }
};
</script>

<style scoped>
</style>